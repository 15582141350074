import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container, Spinner } from "react-bootstrap";

const Document = ({ title, url }) => {
  const [loading, setLoading] = useState(true);
  const hideSpinner = React.useCallback(() => {
    setLoading(false);
  }, []);

  return (<>
    <Container className='h-100 px-0'>
      <div className='h-100 px-0 px-md-3'>
        <div className='h-100'>
          {loading ? (
            <div className='card-img-overlay'>
              <div
                className='w-100 h-100 d-flex align-items-center justify-content-center'>
                <Spinner
                  animation="grow"
                  variant="light"/>
              </div>
            </div>
          ) : null}
          <iframe
            className={`w-100 h-100 ${!loading && 'bg-white-transparent'}`}
            src={url}
            title={title}
            frameBorder="0"
            onLoad={hideSpinner}
          />
        </div>
      </div>
    </Container>
  </>);
};

Document.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

Document.defaultProps = {};

export default Document;
